


















import { useMutation } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import TaskForm from "@/app/components/Task/TaskForm.vue";
import router from "@/app/core/router";
import { encodeAttachmentInput } from "@/models/AttachmentInput";
import { TASK_DATA } from "@/shared/constants/components/Task/Task";
import { useRoute } from "@/shared/hooks";
import { useTask } from "@/shared/hooks/fetchers";
import { useNotification } from "@/shared/hooks/notification";
import { TaskInputExt } from "@/shared/types/components/Task/Task";
import { TaskFormTasks } from "@/shared/types/components/Task/TaskForm";
import { CreateProjectTaskDocument, TaskInput } from "@/shared/types/schema.types";

export default defineComponent({
  components: {
    TaskForm,
    BackLink, 
  },
  setup() {
    const initialTasks = ref<TaskFormTasks>([]);
    const taskData = ref<TaskInputExt>(
      _.cloneDeep(TASK_DATA),
    );
    const route = useRoute();
    const parentTaskId = route.query.parentTaskId;
    const projectId = route.params.id;
    const { mutate: createTaskMutation, onError, onDone } = useMutation(CreateProjectTaskDocument);
    const { errNotification } = useNotification();
    const { onResult: onFetchParentTask } = useTask({ id: parentTaskId as string }, { enabled: !!parentTaskId });

    onFetchParentTask((res) => {
      initialTasks.value = _.compact([res.data.task] as TaskFormTasks);
      taskData.value.parentTaskId = res.data.task.id;
    });

    const backLink = computed(() => `/projects/${projectId}`);

    const isDisabled = computed(
      () =>
        !Object.keys(
          _.pick(taskData.value, [
            "deadline",
            "name",
          ]),
        ).every((el) => !!taskData.value[el as keyof TaskInput]),
    );
    async function createTask() {
      await createTaskMutation({
        taskInput: {
          ...taskData.value,
          attachmentInputs: encodeAttachmentInput(taskData.value.attachmentInputs),
        },
        id: projectId, 
      });
    }

    onDone(() => {
      router && router.push(`/projects/${projectId}`);
    });

    onError((err) => {
      errNotification(err);
    });

    return {
      backLink,
      createTask,
      isDisabled,
      taskData, 
    };
  },
});
